// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// images
import MapImage from 'images/mapsImages/brasilia-detalhes.png';

// Components
import SEO from 'components/seo';
import { device } from 'components/device';
import Layout from 'components/layout';
import AddressTimes from 'components/units/unitPages/unit/addressTimes';
// import Downloads from 'components/unitPages/details/downloads'
import FeaturedMarketing from 'components/units/unitPages/unit/detailsFeaturedMarketing';
// import PublicTransportation from 'components/unitPages/details/publicTransportation';
import SocialShare from 'components/units/unitPages/details/socialShare';
import TextSection from 'components/units/unitPages/details/TextSection';
import DetailsTitle from 'components/units/unitPages/details/DetailsTitle';

import BreadCrumb from 'components/breadCrumb';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};
  @media ${device.mobile} {
    padding: 0 20px 20px;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const renderFeaturedMarketing = page => (
  <FeaturedMarketing
    servicesList={getMarkdown(page, 'ckhow615k02vw09269d61hd03', true)}
    singleText={getMarkdown(page, 'ckhow615k02vw09269d61hd03')}
    asset={getMarkdown(page, 'ckhow615k02vw09269d61hd03', false, true)}
    oneBg
  />
);

const renderPage = (page, isDesktop, location) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <BreadCrumb
        details
        markdown={getMarkdown(page, 'ckhovsj1k02m50a65afkj3f00', true)}
      />
      <DetailsTitle
        titleWidthMobile="17rem"
        littleTop
        markdown={getMarkdown(page, 'ckhovntag02j90a22393jigjn', true)}
      />
    </Part>
    {!isDesktop && renderFeaturedMarketing(page)}
    <Container>
      <Part gridColumn={'1 / span 6'}>
        {isDesktop && renderFeaturedMarketing(page)}
        {/* About */}
        <TextSection
          markdown={getMarkdown(page, 'ckhowj98g035w0a65a0hbo3xp', true)}
          doNotApplyDisplayBlock
          gridArea="ABT"
          isAbout
          isAboutMobile
          isShowing
        />
        {/* Structure */}
        <TextSection
          markdown={getMarkdown(page, 'ckhowv8k803ez0a22i5srcjcf', true)}
          doNotApplyDisplayBlock
          gridArea="STRUC"
          isAboutMobile
          isShowing
        />
        <TextSection
          markdown={getMarkdown(page, 'ckhox362003lb0926uziyivh2', true)}
          doNotApplyDisplayBlock
          gridArea="ALI"
          isAboutMobile
          isShowing
        />
        {/* Transfer Flow */}
        <TextSection
          markdown={getMarkdown(page, 'clmjauankfohy0amu7btttljw', true)}
          doNotApplyDisplayBlock
          gridArea="TRF"
          isAboutMobile
          isShowing
        />
        {/* Others Units Mobile */}
        <TextSection
          markdown={getMarkdown(page, 'ckiqwvlx42s3f0b21tgnsy86u', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
        />
      </Part>
      <Part gridColumn={'8 / span 5'}>
        <AddressTimes
          markdown={getMarkdown(page, 'ckhousjio01x00b76oxsak0a6', true)}
          fragments={getMarkdown(page, 'ckhousjio01x00b76oxsak0a6')}
          location={{ lat: -15.8351173, lng: -47.9117906 }}
          zoom={16}
          isWhatsapp
          isDetails
          brasiliaDetalhesPage
          mapPreview={MapImage}
          isStatic
          isDesktop={isDesktop}
        />
        {/* Schedule */}
        <TextSection
          markdown={getMarkdown(page, 'ckhow9bzc02y60a22d5g6ii3i', true)}
          gridArea="SCH"
          isShort
          isAboutMobile
          isShowing
        />
        {/* Parking */}
        <TextSection
          markdown={getMarkdown(page, 'ckhowbioo030i0926vm449k0g', true)}
          gridArea="PAR"
          isShort
          isAboutMobile
          isShowing
        />
        {/* <PublicTransportation
          markdown={getMarkdown(
            page,
            'ckhowy4q803hb0a22ayqdvol8',
            true
          )}
          fragments={getMarkdown(page, 'ckhowy4q803hb0a22ayqdvol8')}
          whichLine="Brasilia"
        /> */}
        {/* <Downloads /> */}

        {/* Others Units */}
        <TextSection
          markdown={getMarkdown(page, 'ckiqwvlx42s3f0b21tgnsy86u', true)}
          isShort={true}
          gridArea="PAR"
          isAboutMobile
          isOthersUnits
          isShowing
          isOthersUnitsMobile
        />
        {isDesktop && (
          <SocialShare
            location={location}
            title="Detalhes da Unidade Brasília"
          />
        )}
      </Part>
    </Container>
    {!isDesktop && (
      <SocialShare location={location} title="Detalhes da Unidade Brasília" />
    )}
  </GridContainer>
);

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const BrasíliaDetails = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckhow615k02vw09269d61hd03', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop, location)}
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckox864w86hhb0a242yellkcg" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <BrasíliaDetails
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
